<template>
  <CRow>


    <CCol col="12" md="12">
      <CCard>
        <CCardHeader>
          <CIcon name="cil-justify-center"/> ¡Bienvenido!
        </CCardHeader>
        <CCardBody>
          <CAlert show color="success">
            <h4 class="alert-heading"></h4>
            <p><strong>Antes de solicitar cédula de admisión</strong></p>
            <p><br>Es muy importante que leas la siguiente información antes de continuar con tu trámite, pues de ello
              dependerá tu ingreso a esta institución, en caso de aprobar tu examen y ser aceptado.</p>

              <ul>
                <li>
                  Es tu
                  responsabilidad como aspirante realizar correctamente el registro y concluirlo de acuerdo a las fechas
                  establecidas en la convocatoria de admisión.
                </li>
                <li>
                  Recuerda que para poder obtener tu cédula de admisión es indispensable concluir con todo el
                  proceso, no únicamente realizar el pago.
                </li>
                <li>
                  Al registrarte, estás solicitando a la Universidad Politecnica del Bicentenario sustentar los
                  exámenes de admisión, sin que esto signifique que has sido aceptado como alumno para iniciar tu
                  formación académica dentro de esta institución.
                </li>
                <li>
                  Se cancelará el registro del aspirante que sea suplantado o que proporcione documentos o
                  información falsos.
                </li>
                <li>
                  El pago por derecho a exámenes de admisión deberás realizarlo con el formato que se te asignará al
                  realizar tu registro en el sistema, el cual es único, intransferible y válido únicamente para el proceso
                  seleccionado.
                </li>
                <li>
                  Por disposición institucional, el pago por concepto de cédula de admisión no es reembolsable.
                </li>
                <li>
                  El día de los exámenes deberás presentar la cédula con fotografía impresa. Los exámenes por ningún
                  motivo podrán presentarse fuera de las fechas programadas.
                </li>
                <li>
                  Obtendrás calidad de aceptado en la Universidad Politecnica del Bicentenario únicamente si apruebas
                  el examen de admisión y compruebas que la información que ingresaste en el sistema es verídica. </p>

                </li>
                <li>
                  Obtendrás la calidad de alumno, si habiendo sido aceptado, satisfaces los requisitos académicos y
                  administrativos definidos en el Programa Educativo, formalizando tu inscripción, de acuerdo a las fechas
                  establecidas en el calendario académico de la Universidad Politecnica del Bicentenario.
                </li>
              </ul>
            <p>Consulta
              <a href="https://upbicentenario.edu.mx/aviso-de-privacidad/" target="_blank">aqui</a> nuestro aviso de privacidad.
            </p>

<CCol style="text-align: center;">
  <button @click="aceptar_aviso()" class="btn btn-success">
    Acepto las condiciones y el aviso de privacidad de la Universidad Politecnica del Bicentenario.
  </button>
</CCol>



          </CAlert>
        </CCardBody>
      </CCard>
    </CCol>

  </CRow>
</template>

<script>
export default {
  name: 'Alerts',
  data () {
    return {
      id:localStorage.getItem('id'),
      dismissSecs: 10,
      dismissCountDown: 10,
      alert1: true,
      alert2: true
    }
  },
  methods: {
    aceptar_aviso(){
      this.$http.get("aceptar_aviso/" + this.id).then(
              response => {
                // console.log(response.data)
               // this.estados = response.data.estados;
                //console.log(this.kardex);
                this.$router.push('/admision/datosPersonales')
              },
              error => {
                this.StepError(error);
              }
      );
    },
    countDownChanged (dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert () {
      this.dismissCountDown = this.dismissSecs
    },
    showDismissibleAlerts () {
      ['alert1', 'alert2'].forEach(alert => this[alert] = true)
    }
  }
}
</script>
